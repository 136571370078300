$(document).ready(function ($) {
	//js validation
	initJsValidation();
	//hide antispam honeypot form fields
	$("#contactform [name='time']").hide();
	$("#newsletter-form [name='preference']").hide();
});

//initializes validate.js rules from Zend backend validation rules in data attributes
function initJsValidation()
{
	$('form').each(function () {
		if ($(this).attr('data-validation-initialized'))
			return;

		if ($(this).attr('data-js-validation')) {
			//additional rules
			//newletter lists multicheckbox
			var rules = {
				'listsIds[]': {
					required: true,
					minlength: 1
				}
			};
			var messages = {
				//'listsIds[]': JSON.parse($('.error-placement-wrapper[data-element-id="listsIds[]"]').attr('data-error-messages'))
				'listsIds[]': "Pole nesmí zůstat prázdné"
			};
			var errorWrapperSelector = $(this).attr('data-error-wrapper-selector');
			var errorWrapperClass = $(this).attr('data-error-wrapper-class');
			var errorClassOnElement = $(this).attr('data-error-class-on-element');
			$('input[data-error-messages], textarea[data-error-messages], checkbox[data-error-messages], select[data-error-messages]', this).each(function () {
				messages[$(this).attr('name')] = JSON.parse($(this).attr('data-error-messages'));
			});

			$('*', this).filter(':input').each(function () {
				var i = 0;
				if (!$(this).attr('id'))
					return;
				var depends = $(this).attr('depends');
				if (depends !== undefined)
					depends = JSON.parse(depends);
				else
					return;

				var dependentValSelectors = [];
				if (!Array.isArray(depends.key))
					depends.key = [depends.key];

				for (i in depends.key) {
					var key = depends.key[i];
					var dependentElement = $('*[name="' + key + '"]');
					var dependentValSelector = (dependentElement.attr('type') == 'radio') ? 'input[name="' + key + '"]:checked' : '#' + key;
					dependentValSelectors.push(dependentValSelector);
				}
				var elementId = $(this).attr('id');
				rules[elementId] = {
					required: {
						depends: function () {
							for (i in dependentValSelectors) {
								if ($(dependentValSelectors[i]).val() == depends.value)
									return true;
							}
							return false;
						}
					}
				};
				if (depends.hasOwnProperty('additionalValidators')) {
					for (i in depends.additionalValidators) {
						rules[elementId][i] = depends.additionalValidators[i];
					}
				}
			});
			$(this).validate({
				'errorClass': $(this).attr('data-error-class'),
				'errorElement': $(this).attr('data-error-element'),
				errorPlacement: function (error, element) {
					if(element.attr('name') == 'listsIds[]') {
						$('.error-placement-wrapper[data-element-id="listsIds[]"]').html(error);
					} else {
						$('.error-placement-wrapper[data-element-id=' + element.attr('id') + ']').html(error);
					}
				},
				highlight: function (element) {
					if (errorWrapperSelector && errorWrapperClass) {
						$(element).closest(errorWrapperSelector).addClass(errorWrapperClass);
					}
					if (errorClassOnElement) {
						$(element).addClass(errorClassOnElement);
					}
				},
				unhighlight: function (element) {
					if (errorWrapperSelector && errorWrapperClass) {
						$(element).closest(errorWrapperSelector).removeClass(errorWrapperClass);
					}
					$('.error-placement-wrapper[data-element-id=' + element.id + ']').empty();
					if (errorClassOnElement) {
						$(element).removeClass(errorClassOnElement);
					}
				},
				'messages': messages,
				rules: rules,
				submitHandler: function (form) {
					//prevent multiple form submissions
					if (!$(form).attr('data-form-submitted') || $(this).attr('data-allow-multiple-submissions')) {
						$(form).attr('data-form-submitted', '1');
						if ($(form).hasClass('ajax')) {
							$(form).myAjax($(form).attr('data-to-inject'));
						} else {
							form.submit();
						}
					}
				}
			});
			$(this).attr('data-validation-initialized', '1');
		} else {
			//prevent multiple form submissions on no-js-validated forms
			if (!$(this).hasClass('ajax') && !$(this).attr('data-allow-multiple-submissions')) {
				$(this).submit(function () {
					if (!$(this).attr('data-form-submitted')) {
						$(this).attr('data-form-submitted', '1');
						this.submit();
						return true;
					} else {
						return false;
					}
				});
			}
		}
	});
}
