/*
 *
 * PROJECT NAME
 * Author: Martin Winkler
 * url: http://www.martinwinkler.cz/
 *
 */

/**********************************************
VARIOUS
**********************************************/

// Scroll to anchor
$(function() {
  scrollToAnchor();
});

// Dropdown
$('[data-purpose="dropdown-trig"]').on('click', function() {
  var id = $(this).data('id');
  var dropdown = $(document.getElementById(id));
  var dropdowns = $('[data-purpose="dropdown"]');
  var header = $('.header');

  if (!dropdown.hasClass('active')) {
    dropdowns.removeClass('active');
    dropdown.addClass('active');
    header.addClass('dropdown-opened');
  } else {
    dropdown.removeClass('active');
    header.removeClass('dropdown-opened');
  }

  return false;
});

// Scrollspy
$(function() {
  'use strict';

  var section = document.querySelectorAll('.anchor');
  var sections = {};
  var i = 0;

  $(window).on('load scroll', function() {
    var scrollPosition = document.documentElement.scrollTop || document.body.scrollTop;
    scrollPosition = scrollPosition + 1;

    Array.prototype.forEach.call(section, function(e) {
      sections[e.id] = $(e).offset().top;
    });

    for (i in sections) {
      if (sections[i] <= scrollPosition) {
        $('.scrollspy li').removeClass('active');
        $('.scrollspy a[href*=' + i + ']').closest('li').addClass('active');
      }
    }
  });
});

// Wrap table
$(function() {
  $('table').wrap('<div class="table-wrap"></div>');
});

/**********************************************
MODAL BOX
**********************************************/

$(function() {
  var body        = $('body');
  var modalClass  = $('.modal');
  var trigger     = $('.modal-trigger');
  var close       = $('.modal-close');

  trigger.each(function(index, element) {
    var id      = element.hash;
    var modal   = $(id);

    $(element).on('click', function(e) {
      modal.addClass('active');
      $('body').addClass('modal-opened');
    });
  });

  // Close link
  close.on('click', function(e) {
    modalClass.removeClass('active');
    body.removeClass('modal-opened');
    $(window).trigger('modalClose');
  });

  // Overlay click
  $(window).on('click', function(e) {
    $('.modal-box-wrap').each(function(index, element) {
      if (e.target === element) {
        modalClass.removeClass('active');
        body.removeClass('modal-opened');
        $(window).trigger('modalClose');
      }
    });
  });

  // Escape close
  $(window).on('keyup', function(e) {
    if (e.keyCode === 27) {
      modalClass.removeClass('active');
      body.removeClass('modal-opened');
      $(window).trigger('modalClose');
    }
  });

  $('.modal-box a').on('click', function() {
    $(window).trigger('modalClose');
  });
});


/**********************************************
MOBILE NAV
**********************************************/

$(function() {
  var body              = $('body');
  var mobileNav         = $('.mobile-nav');
  var mobileNavOverlay  = $('.mobile-nav-overlay');

  function showNav() {
    body.addClass('mobile-nav-opened');
  }

  function hideNav() {
    body.removeClass('mobile-nav-opened');
  }

  // Navigation button
  $('.nav-btn').on('click', function(e) {
    if (!body.hasClass('mobile-nav-opened')) {
      showNav();
    }
    else {
      hideNav();
    }
    return false;
  });

  // Mobile nav overlay close
  $('.mobile-nav-overlay').on('click', function(e) {
    hideNav();
  });

  $('.mobile-nav li a').each(function(e) {
    if ($(this).siblings('ul').length > 0) {
      $(this).append('<span class="more"></span>');
    }
  });

  // Mobile nav accordion
  $('.mobile-nav li .more').on('click', function(e) {
    var link = $(this).closest('a');
    var ul = link.siblings('ul');

    if (ul.length > 0) {
      if (!ul.hasClass('active')) {
        link.addClass('active');
        ul.addClass('active');
      } else {
        link.removeClass('active');
        ul.removeClass('active');
      }
      return false;
    }
  });
});

/**********************************************
COOKIEBAR
**********************************************/
/*
$(function() {
  var webCookie = 'ms_veletiny_cookiebar';
  var webCookiePrepend = 'body';
  var cookieisok = ReadCookie(webCookie);
  var testCookie = '';
  SetCookie('testCookie', 'ok', 365);
  var testCookie = ReadCookie('testCookie');

  $(function() {
    if (cookieisok != "ok" && testCookie == "ok") {
      $(webCookiePrepend).prepend("<div class='cookie-notice'>Pro účely analýzy návštěvnosti používáme nástroj Google Analytics, který využívá soubory cookies. Předpokládáme, že s použitím cookies souhlasíte. <a href='javascript:;' class='cookie-button'>SOUHLASÍM</a></div>")
    }

    $('.cookie-button').on('click', function() {
      $('.cookie-notice').hide();
      SetCookie(webCookie, 'ok', 365);
    });
  });
});
*/

/**********************************************
RESIZE END / SCROLL END
**********************************************/

var resizeEnd;
$(window).on('resize', function(e) {
  clearTimeout(resizeEnd);
  resizeEnd = setTimeout(function() {
    $(window).trigger('resizeEnd');
  }, 100);
});

var scrollEnd;
$(window).on('scroll', function(e) {
  clearTimeout(scrollEnd);
  scrollEnd = setTimeout(function() {
    $(window).trigger('scrollEnd');
  }, 100);
});

/**********************************************
SIMPLE LIGHTBOX
**********************************************/

// Simple lightbox
var lightbox = new SimpleLightbox({
  elements: ".fancybox, a[href$='.jpg'], a[href$='.jpeg'], a[href$='.webp'], a[href$='.png'], a[href$='.gif'], [data-fancybox='gallery'], [data-purpose='play']"
});

// Swipe  
document.addEventListener('swiped-left', function(e) {
  if ($('html.slbActive').length > 0) {
    lightbox.next();
  }
});

document.addEventListener('swiped-right', function(e) {
  if ($('html.slbActive').length > 0) {
    lightbox.prev();
  }
});

/**********************************************
SLIDERS
**********************************************/

// Cases slider
if ($('.cases-slider').length > 0 ) {
  new Glide('.cases-slider', {
    type: 'carousel',
    startAt: 0,
    perView: 1,
    focusAt: 'center',
    //animationDuration: 0,
    gap: 0,
    swipeThreshold: false,
    dragThreshold: false,
    breakpoints: {
      991: {
        perView: 1
      },
      575: {
        perView: 1
      }
    }
  }).mount()
}

/****************************************************************************************************************************************************************************************
FUNCTIONS
****************************************************************************************************************************************************************************************/

// Scroll to anchor
function scrollToAnchor() {
  var links = document.querySelectorAll('.to-anchor');
  links.forEach(each => (each.onclick = scrollAnchors));
}

function scrollAnchors(e, respond = null) {
  var distanceToTop = el => Math.floor(el.getBoundingClientRect().top);
  e.preventDefault();
  var targetID = (respond) ? respond.getAttribute('href') : this.getAttribute('href');
  var targetAnchor = document.querySelector(targetID);
  if (!targetAnchor) return;
  var originalTop = distanceToTop(targetAnchor);
  window.scrollBy({ top: originalTop, left: 0, behavior: 'smooth' });
  var checkIfDone = setInterval(function() {
    var atBottom = window.innerHeight + window.pageYOffset >= document.body.offsetHeight - 2;
    if (distanceToTop(targetAnchor) === 0 || atBottom) {
      targetAnchor.tabIndex = '-1';
      targetAnchor.focus();
      window.history.pushState('', '', targetID);
      clearInterval(checkIfDone);
    }
  }, 100);
}

// Cookies
function ReadCookie(name) {
  var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
  return v ? v[2] : null;
}

function SetCookie(name, value, days) {
  var d = new Date;
  d.setTime(d.getTime() + 24*60*60*1000*days);
  document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
}

function DeleteCookie(name) {
  setCookie(name, '', -1);
}